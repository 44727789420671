import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { Link, withRouter } from "react-router-dom";
import StylePrefabs from "../../consts/StylePrefabs";
import AppActions from "../../actions/AppActions";
import ChecklistActions from "../../actions/ChecklistActions";
import Colors from "../../consts/Colors";
import H1 from "../../components/text-elements/heading/H1";
import B2 from "../../components/text-elements/body/B2";
import Share from "../../components/share/Share";
import history from "../../history";
import ChecklistSection from "../../components/checklist-section/ChecklistSection";
import Next from "../../components/ctas/next/Next";
import Modal from "../../components/modal/Modal";
import CompletedChecklistModalComp from "../../components/modal/completed-checklist-modal-comp/CompletedChecklistModalComp";
import SaveChecklistPrompt from "../../components/save-checklist-prompt/SaveChecklistPrompt";
import Devices from "../../consts/Devices";

class Checklist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      latestSectionIndex: null,
      showCompletedModal: false,
    };

    this.startNewSection = this.startNewSection.bind(this);
    this.loadExistingSection = this.loadExistingSection.bind(this);
    this.findLatestSectionIndex = this.findLatestSectionIndex.bind(this);
    this.findChecklist = this.findChecklist.bind(this);
    this.loadNewChecklist = this.loadNewChecklist.bind(this);
    this.checkIfCompletedAndSetComplete =
      this.checkIfCompletedAndSetComplete.bind(this);
  }

  componentDidMount() {
    this.props.setApp({
      hideMenu: false,
      showBlobs: true,
      backButton: { path: "/checklists", text: "Checklists" },
      showBackButton: true,
    });

    if (this.props.match.params.type == "new") {
      this.props.loadNewChecklist(this.props.match.params.checklistId);
    } else {
      let foundChecklist = this.findChecklist(
        this.props.match.params.checklistId
      );
      this.props.loadSavedChecklist(foundChecklist);
      let latestSectionIndex = this.findLatestSectionIndex();
      this.setState({ latestSectionIndex: latestSectionIndex });
      if (!this.props.checklist.complete) {
        this.checkIfCompletedAndSetComplete();
      }
    }
  }

  componentWillUnmount() {
    if (
      this.props.match.params.type === "saved" &&
      !this.props.checklist.haveSeenNamingPrompt
    ) {
      const checklistName = SaveChecklistPrompt(
        this.props.checklist.title,
        this.props.checklist.name
      );
      let checklistToSave = this.props.checklist;
      if (checklistName !== checklistToSave.title) {
        checklistToSave.title = checklistName;
      }
      checklistToSave.haveSeenNamingPrompt = true;
      this.props.updateChecklist(checklistToSave);
    }
  }

  findChecklist(idToFind) {
    let idToFindAsInt = parseInt(idToFind);
    return this.props.savedChecklists.checklists.filter(
      (checklist) => checklist.id === idToFindAsInt
    )[0];
  }

  async startNewSection(indexClicked) {
    let newId = Date.now();

    await this.props.startChecklist({
      checklist: this.props.checklist,
      id: newId,
    });

    let newChecklist = this.findChecklist(newId);

    this.loadNewChecklist(newChecklist, indexClicked);
  }

  loadNewChecklist(checklist, indexClicked) {
    this.props.loadSavedChecklist(checklist);

    if (indexClicked) {
      history.push(`/checklist/saved/${checklist.id}/${indexClicked}`);
    } else {
      history.push(`/checklist/saved/${checklist.id}/0`);
    }
  }

  loadExistingSection(indexClicked) {
    if (indexClicked !== undefined) {
      history.push(
        `/checklist/saved/${this.props.checklist.id}/${indexClicked}`
      );
    } else {
      history.push(
        `/checklist/saved/${this.props.checklist.id}/${this.state.latestSectionIndex}`
      );
    }
  }

  findLatestSectionIndex() {
    let latestIndex;
    let allChecklistSections = this.props.checklist.sections;
    for (let section of allChecklistSections) {
      if (!section.completed) {
        latestIndex = allChecklistSections.indexOf(section);
        return latestIndex;
      } else {
        latestIndex = allChecklistSections.indexOf(section);
      }
    }
    return latestIndex;
  }

  checkIfCompletedAndSetComplete() {
    let allCompleted = true;
    for (let section of this.props.checklist.sections) {
      if (!section.completed) {
        allCompleted = false;
      }
    }

    if (allCompleted === true) {
      this.setState({
        showCompletedModal: true,
      });
      let checklistToSave = this.props.checklist;
      checklistToSave.complete = true;
      this.props.updateChecklist(checklistToSave);
    }
  }

  render() {
    if (!this.props.checklist.title) {
      return <Wrapper />;
    }

    return (
      <>
        <>
          {this.state.showCompletedModal === true ? (
            <ChecklistModal
              closeModal={() => this.setState({ showCompletedModal: false })}
              component={
                <CompletedChecklistModalComp
                  checklistName={this.props.checklist.name}
                  history={this.props.history}
                />
              }
            />
          ) : null}
        </>

        <Wrapper>
          <Heading>
            <Title>
              {this.props.checklist.title !== this.props.checklist.name &&
              this.props.checklist.name
                ? `${this.props.checklist.title} - ${this.props.checklist.name}`
                : this.props.checklist.title}
            </Title>
            <SubTitle>
              {this.props.checklist.blurb}{" "}
              <a
                href="#"
                onClick={() => {
                  this.props.telephone("0800 988 7847", this.props.platform);
                }}
              >
                please call us
              </a>
              .
            </SubTitle>
            <Share
              onClick={() => {
                this.props.openShareSheet({ url: window.location.href });
              }}
            />
          </Heading>

          <ChecklistSections>
            {this.props.checklist.sections.map((section, i) => {
              return (
                <ChecklistSection
                  onClick={
                    this.props.match.params.type == "new"
                      ? (sectionIndex) => this.startNewSection(sectionIndex)
                      : (sectionIndex) => this.loadExistingSection(sectionIndex)
                  }
                  i={i}
                  section={section}
                  key={i}
                />
              );
            })}
          </ChecklistSections>

          <StartWrapper>
            <NextBtn
              onClick={
                this.props.match.params.type == "new"
                  ? this.startNewSection
                  : this.loadExistingSection
              }
            >
              {this.props.match.params.type == "new"
                ? "Start checklist"
                : "Continue section"}
            </NextBtn>
          </StartWrapper>
        </Wrapper>
      </>
    );
  }
}

const ChecklistModal = styled(Modal)`
  @media (max-width: 768px) {
    padding: 5px;
    transform: translateY(0%) translateX(-50%);
    top: 10px;
    bottom: auto;
    box-shadow: none;
    width: calc(100vw - 10px);
  }
`;

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper};
  ${StylePrefabs.fullHeightMobileWithHeader}
  padding-bottom: 2rem;
  @media ${Devices.laptop} {
    max-width: 500px !important;
  }
`;

const Heading = styled.div`
  text-align: center;
`;

const Title = styled(H1)`
  color: ${Colors.black};
  text-align: center;
`;

const SubTitle = styled(B2)`
  color: ${Colors.black};
  max-width: 240px;
  margin: 1rem auto 1rem auto;
  text-align: center;
  display: block;

  @media ${Devices.laptop} {
    margin: 1rem auto 1.5rem auto;
    max-width: 320px;
  }

  a {
    color: ${Colors.black};
    font-weight: bold;
    text-decoration: none;
  }
`;

const ChecklistSections = styled.div`
  padding: 0 0.5rem;
  margin-top: 1rem;

  @media ${Devices.laptop} {
    margin-top: 1.5rem;
  }
`;

const StartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 3rem 0;
`;

const NextBtn = styled(Next)``;

const mapStateToProps = (state) => {
  return {
    checklist: state.checklist,
    savedChecklists: state.savedChecklists,
    platform: state.app.platform,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setApp: (data) => {
      dispatch(AppActions.setApp(data));
    },
    loadNewChecklist: (id) => {
      dispatch(ChecklistActions.loadNewChecklist(id));
    },
    loadSavedChecklist: (checklist) => {
      dispatch(ChecklistActions.loadSavedChecklist(checklist));
    },
    startChecklist: (data) => {
      dispatch(ChecklistActions.startChecklist(data));
    },
    updateChecklist: (data) => {
      dispatch(ChecklistActions.updateChecklist(data));
    },
    openShareSheet: (url) => {
      dispatch(AppActions.openShareSheet(url));
    },
    telephone: (number, platform) => {
      dispatch(AppActions.telephone(number, platform));
    },
  };
};

const connectedChecklist = connect(
  mapStateToProps,
  mapDispatchToProps
)(Checklist);

export default withRouter(connectedChecklist);
