import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import Slider from "react-slick";
import ChecklistActions from "../../../actions/ChecklistActions";
import ProgressBar from "../../progress-bar/ProgressBar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Devices from "../../../consts/Devices";
import Colors from "../../../consts/Colors";
import H1 from "../../text-elements/heading/H1";
import H2 from "../../text-elements/heading/H2";
import B2 from "../../text-elements/body/B2";
import Next from "../../ctas/next/Next";
import history from "../../../history";
import Body from "../carousel-body/CarouselBody";
import Previous from "../../ctas/previous/Previous";
import YesNoToggle from "../../yes-no-toggle/YesNoToggle";
import QuestionTooltipModal from "../../question-tooltip-modal/QuestionTooltipModal";
import OnboardingHeading from "../../onboarding-heading/OnboardingHeading";
import Swiper from "react-id-swiper";
import AppActions from "../../../actions/AppActions";

class SectionCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: this.props.latestQuestionIndex,
      section: this.props.checklist.sections[this.props.match.params.sectionId],
      swiper: null,
      awaitCarouselRender: true,
    };

    this.yesClick = this.yesClick.bind(this);
    this.noClick = this.noClick.bind(this);
    this.handleYesNoToggleClick = this.handleYesNoToggleClick.bind(this);
    this.isQuestionAnswered = this.isQuestionAnswered.bind(this);
    this.isSectionCompleted = this.isSectionCompleted.bind(this);
    this.handleTooltipArrowClick = this.handleTooltipArrowClick.bind(this);
    this.handleTooltipCrossClick = this.handleTooltipCrossClick.bind(this);
    this.carouselItemRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ awaitCarouselRender: false });
    }, 400);
  }

  yesClick(question) {
    question.answered = true;
    question.tooltipViewed = false;
    this.props.setQuestionAnswered(question);

    let isSectionCompleted = this.isSectionCompleted();
    let sectionToSave = this.state.section;
    sectionToSave.completed = isSectionCompleted;
    this.props.setSectionCompleted(sectionToSave);
    this.props.updateChecklist(this.props.checklist);
    if (question.yes_tooltip.title || question.yes_tooltip.body) {
    } else {
      if (
        isSectionCompleted ||
        this.state.section.questions.length - 1 === this.state.currentIndex
      ) {
        setTimeout(
          () =>
            history.push(
              `/checklist/saved/${this.props.match.params.checklistId}`
            ),
          500
        );
      } else {
        setTimeout(() => this.state.swiper.slideNext(), 500);
      }
    }
  }

  noClick(question) {
    question.answered = false;
    question.tooltipViewed = false;
    this.props.setQuestionAnswered(question);
    let isSectionCompleted = this.isSectionCompleted();
    let sectionToSave = this.state.section;
    sectionToSave.completed = isSectionCompleted;
    this.props.setSectionCompleted(sectionToSave);

    this.props.updateChecklist(this.props.checklist);
    if (question.no_tooltip.title || question.no_tooltip.body) {
    } else {
      if (
        isSectionCompleted ||
        this.state.section.questions.length - 1 === this.state.currentIndex
      ) {
        setTimeout(
          () =>
            history.push(
              `/checklist/saved/${this.props.match.params.checklistId}`
            ),
          500
        );
      } else {
        setTimeout(() => this.state.swiper.slideNext(), 500);
      }
    }
  }

  handleTooltipArrowClick(question) {
    question.tooltipViewed = true;
    this.props.setTooltipViewed(question);
    this.props.updateChecklist(this.props.checklist);
    if (this.state.section.questions.length - 1 === this.state.currentIndex) {
      history.push(`/checklist/saved/${this.props.match.params.checklistId}`);
    } else {
      this.state.swiper.slideNext();
    }
  }

  handleTooltipCrossClick(question) {
    question.tooltipViewed = true;
    this.props.setTooltipViewed(question);
    this.props.updateChecklist(this.props.checklist);
  }

  isQuestionAnswered(question) {
    if (question.answered === true) {
      return true;
    } else if (question.answered === undefined) {
      return;
    } else {
      return false;
    }
  }

  isSectionCompleted() {
    let numCompleted = this.state.section.questions.filter(
      (question) => question.answered === true
    ).length;
    return numCompleted === this.state.section.questions.length ? true : false;
  }

  handleYesNoToggleClick(val, question) {
    val ? this.yesClick(question) : this.noClick(question);
  }

  render() {
    const params = {
      slidesPerView: 5,
      spaceBetween: 50,
      speed: 400,
      centeredSlides: true,
      initialSlide: this.props.latestQuestionIndex,
      allowTouchMove: false,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      breakpoints: {
        1440: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        768: {
          allowTouchMove: true,
          slidesPerView: 1.1,
          spaceBetween: 10,
        },
      },
      on: {
        slideChange: () =>
          this.state.swiper &&
          this.setState({ currentIndex: this.state.swiper.activeIndex }),
      },
    };

    return (
      <>
        {this.state.currentIndex !== null ? (
          <>
            <PageWrapper>
              <HeadingWrapper>
                <Count>
                  {this.state.currentIndex + 1} /{" "}
                  {this.state.section.questions.length}
                </Count>
                <Heading>{this.state.section.section_title}</Heading>
              </HeadingWrapper>
              <SwiperWrapper>
                {this.state.awaitCarouselRender === false ? (
                  <Swiper
                    getSwiper={(swiperEl) =>
                      this.setState({ swiper: swiperEl })
                    }
                    {...params}
                  >
                    {this.state.section.questions.map((question, index) => {
                      const isActive = index === this.state.currentIndex;
                      return (
                        <CarouselItem
                          key={index}
                          onClick={() => {
                            this.state.swiper.slideTo(index);
                          }}
                          ref={this.carouselItemRef}
                        >
                          <CarouselInnerWrapper>
                            <CarouselHeading>
                              <Bar isActive={isActive} />
                              {question.question_heading}
                            </CarouselHeading>
                            <Body>{question.question_body}</Body>
                            <ControlsWrapper>
                              <ControlsInner>
                                <YesNoToggle
                                  onClick={(val) =>
                                    this.handleYesNoToggleClick(val, question)
                                  }
                                  selected={this.isQuestionAnswered(question)}
                                  isNextOption={question.next_option}
                                />
                              </ControlsInner>
                            </ControlsWrapper>
                          </CarouselInnerWrapper>
                        </CarouselItem>
                      );
                    })}
                  </Swiper>
                ) : null}
              </SwiperWrapper>

              <ProgressBarWrapper>
                <ProgressBar
                  completed={this.state.currentIndex + 1}
                  total={this.state.section.questions.length}
                />
              </ProgressBarWrapper>
            </PageWrapper>

            <>
              {this.carouselItemRef.current ? (
                <QuestionTooltipModal
                  section={this.state.section}
                  currentQuestionIndex={this.state.currentIndex}
                  isQuestionAnswered={this.isQuestionAnswered}
                  onArrowClick={() => {
                    this.handleTooltipArrowClick(
                      this.state.section.questions[this.state.currentIndex]
                    );
                  }}
                  onCrossClick={() => {
                    this.handleTooltipCrossClick(
                      this.state.section.questions[this.state.currentIndex]
                    );
                  }}
                  carouselItemWidth={parseFloat(
                    this.carouselItemRef.current.style.width +
                      this.carouselItemRef.current.style.marginRight
                  )}
                  telephone={(number) => {
                    this.props.telephone(number, this.props.platform);
                  }}
                />
              ) : null}
            </>
          </>
        ) : null}
      </>
    );
  }
}

const PageWrapper = styled.div`
  /* position: relative; */
`;

const HeadingWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const SwiperWrapper = styled.div`
  padding-bottom: 100px;

  .swiper-container {
    overflow: visible !important;

    > div {
      display: felx;
    }

    .swiper-slide {
      opacity: 0.5;
      transition: opacity 0.3s, transform 0.3s;

      .yes-no-toggle {
        pointer-events: none;
      }
    }
    .swiper-slide-active {
      .yes-no-toggle {
        pointer-events: auto;
      }

      opacity: 1;
      transition: opacity 0.3s, transform 0.3s;

      @media ${Devices.tablet} {
        transform: scale(1.05);
      }

      @media ${Devices.laptopL} {
        transform: scale(1.08);
      }
    }
  }
`;

const Count = styled(B2)`
  color: ${Colors.grey};
  margin-top: 0rem;
  text-align: center;

  /* @media ${Devices.mobileM} {
    margin-top: 2rem;
  }

  @media ${Devices.laptop} {
    margin-top: rem;
  } */
`;

const Heading = styled(H1)`
  margin: 0.5rem 0 1.5rem;
  z-index: 200;
  color: ${Colors.black};
  text-align: center;
  pointer-events: none;

  @media ${Devices.mobileM} {
    margin: 0.5rem 0 1.5rem;
  }

  @media ${Devices.mobileTall} {
    margin: 0.5rem 0 3.5rem;
  }

  @media ${Devices.laptop} {
    margin: 0.5rem 0 2.5rem;
  }

  @media ${Devices.laptopL} {
    margin: 0.5rem 0 4.5rem;
  }
`;

const CarouselItem = styled.div`
  cursor: pointer;

  position: relative;
  border-radius: 10px;
  background-color: ${Colors.white};
  height: 250px;
  outline: none;
  /* @media ${Devices.mobileM} {
    height: 300px;
  } */

  @media ${Devices.mobileM} {
    height: 275px;
  }

  @media ${Devices.mobileTall} {
    height: 350px;
  }

  @media ${Devices.laptop} {
    height: 360px;
    transition: all 0.2s ease-out;

    &:hover {
      box-shadow: ${Colors.boxShadow};
    }
  }

  @media ${Devices.laptopL} {
    height: 440px;
  }

  @media ${Devices.tenEighty} {
    height: 500px;
  }
`;

const CarouselInnerWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 2rem 2rem 3rem 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${Devices.tablet} {
    padding: 1rem 2rem 2rem 2rem;
  }
`;

const Bar = styled.div`
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  width: 12px;
  border: 1px solid ${Colors.turquoise};
  opacity: ${(props) => (props.isActive ? 1.0 : 0)};
  transition: opacity 0.5s;
  margin-bottom: 1rem;
  @media ${Devices.mobileM} {
    margin-bottom: 1.5rem;
  }
`;

const CarouselHeading = styled(H2)`
  position: relative;
  text-align: center;
  margin-bottom: 2em;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
`;

const ControlsWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -2.2rem;

  @media ${Devices.mobileL} {
    bottom: -1.8rem;
  }

  @media ${Devices.mobileM} {
    bottom: -1.4rem;
  }

  @media ${Devices.mobileS} {
    bottom: -1.2rem;
  }
`;

const ControlsInner = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  padding: 0.5rem;
  position: absolute;
  bottom: 0px;
`;

const TooltipWrapper = styled.div`
  /* z-index: 5;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0; */
`;

const mapStateToProps = (state) => {
  return {
    checklist: state.checklist,
    platform: state.app.platform,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQuestionAnswered: (data) => {
      dispatch(ChecklistActions.setQuestionAnswered(data));
    },
    updateChecklist: (data) => {
      dispatch(ChecklistActions.updateChecklist(data));
    },
    setSectionCompleted: (data) => {
      dispatch(ChecklistActions.setSectionCompleted(data));
    },
    setTooltipViewed: (data) => {
      dispatch(ChecklistActions.setTooltipViewed(data));
    },
    telephone: (number, platform) => {
      dispatch(AppActions.telephone(number, platform));
    },
  };
};

const connectedSectionCarousel = connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionCarousel);

export default withRouter(connectedSectionCarousel);
